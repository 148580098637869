<template lang="pug">
  .edit-profile
      form.edit-profile-data(@submit.prevent="handleEditProfile" novalidate)
        .edp-head
          .csoff-wrapper(@click="edCrossHandler")
            img(:src="require('@/assets/images/personal/sunduk-white/cross_starz57.svg')" alt="cross")
          span.edp-repl {{ $t('profile.breadcrumb.editProfileLabel') }}

        .edp-body
          .top-section
            .left-group
              .addresses-container-left
                .edp-field-wrapper
                  TextInput(name="username" :renderedTextLabel="$t('profile.editProfile.editProfileUsernameLabel')"
                            :key="updateKey1" :defaultValue="defaultUsername"
                            :floatingLabel="true" vid="username" :disabledProps="true"
                            @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                            :skipIfEmpty="true" :clearFields="clearFields")

                .edp-field-wrapper.names-wrapper
                  .fname-wrapper
                    TextInput(name="fname" :renderedTextLabel="$t('profile.editProfile.editProfileFirstnameLabel')"
                              :key="updateKey2" :defaultValue="defaultFirstname"
                              :floatingLabel="true" vid="fname" :disabledProps="true"
                              @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                              :skipIfEmpty="true" :clearFields="clearFields")

                  .lname-wrapper
                    TextInput(name="lname" :renderedTextLabel="$t('profile.editProfile.editProfileLastnameLabel')"
                              :key="updateKey3" :defaultValue="defaultLastname"
                              :floatingLabel="true" vid="lname" :disabledProps="true"
                              @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                              :skipIfEmpty="true" :clearFields="clearFields")

                .edp-field-wrapper.dpi-wrapper
                  ValidationProvider.datepicker-wrapper(@click="switchDropdown('days')"
                    tag="div"
                    name="datepicker"
                    :skipIfEmpty="false" vid="datepicker"
                    v-slot="{ errors, required }")
                    Dropdown(:itemArray="dateArray" :open="expandDaysDD"
                      @emitDDItem="listenToggle($event, 'day')"
                      dropdownType="datepicker" :hasError="errors[0]")
                      template(v-slot:date-picker)
                        .date-picker-content
                          .date-picker__text(:class="{ 'filled': !isNaN(parseInt(selectedDayValue)) }") {{ selectedDayValue }}
                          .date-picker__chevron

                  ValidationProvider.monthpicker-wrapper(@click="switchDropdown('month')"
                    tag="div"
                    name="monthpicker"
                    :skipIfEmpty="false" vid="monthpicker"
                    v-slot="{ errors, required }")
                    Dropdown(:itemArray="monthArray" :open="expandMonthDD"
                      @emitDDItem="listenToggle($event, 'month')"
                      :hasError="errors[0]"
                      dropdownType="datepicker")
                      template(v-slot:month-picker)
                        .month-picker-content
                          .month-picker__text(:class="{ 'filled': monthArray.find(month => month.title == selectedMonthValue) != undefined }") {{ selectedMonthValue }}
                          .month-picker__chevron

                  ValidationProvider.yearpicker-wrapper(@click="switchDropdown('year')"
                    tag="div"
                    name="yearpicker"
                    :skipIfEmpty="false" vid="yearpicker"
                    v-slot="{ errors, required }")
                    Dropdown(:itemArray="yearArray" :open="expandYearDD"
                      @emitDDItem="listenToggle($event, 'year')"
                      :hasError="errors[0]"
                      dropdownType="datepicker")
                      template(v-slot:year-picker)
                        .year-picker-content
                          .year-picker__text(:class="{ 'filled': !isNaN(parseInt(selectedYearValue)) }") {{ selectedYearValue }}
                          .year-picker__chevron

                .edp-field-wrapper.form-radio
                  .form-radio-msex(@click="checkSex('male')")
                    .mx-wrapper
                      input(type="radio" id="male")
                      label.lab-male
                    span {{ $t('profile.editProfile.sexMaleLabel') }}

                  .form-radio-fsex(@click="checkSex('female')")
                    .fx-wrapper
                      input(type="radio" id="female")
                      label.lab-female
                    span {{ $t('profile.editProfile.sexFemaleLabel') }}




              .addresses-container-right
                .edp-field-wrapper.region-wrapper
                  ValidationProvider.country-wrapper(@click="switchDropdown('country')"
                                                    tag="div" name="countryPicker"
                                                    :skipIfEmpty="false" vid="countryPicker"
                                                    v-slot="{ errors, required }")
                    Dropdown(:itemArray="getCountries" :open="expandCountryDD"
                              @emitDDItem="listenToggle($event, 'country')"
                              :hasError="errors[0]" dropdownType="datepicker")
                      template(v-slot:country-picker)
                        .country-picker-content
                          .country-picker__text(:class="{ 'filled': selectedCountryValue != $t('profile.editProfile.editProfileCountryLabel') }") {{ selectedCountryValue }}
                          .country-picker__chevron

                  .state-wrapper
                    TextInput(name="state" :renderedTextLabel="$t('profile.editProfile.editProfileStateLabel')"
                      :key="updateKey5" :defaultValue="defaultState"
                      :floatingLabel="true" vid="state" :disabledProps="true"
                      @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                      :skipIfEmpty="true" :clearFields="clearFields")

                .edp-field-wrapper.region-wrapper
                  .city-wrapper
                    TextInput(name="city" :renderedTextLabel="$t('profile.editProfile.editProfileCityLabel')"
                      :key="updateKey9" :defaultValue="defaultCity"
                      :floatingLabel="true" vid="city" :disabledProps="true"
                      @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                      :skipIfEmpty="true" :clearFields="clearFields")

                .edp-field-wrapper.address-wrapper
                  TextInput(name="address" :renderedTextLabel="$t('profile.editProfile.editProfileAddressLabel')"
                    :key="updateKey6" :defaultValue="defaultAddress"
                    :floatingLabel="true" vid="address" :disabledProps="true"
                    @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                    :skipIfEmpty="true" :clearFields="clearFields")

                .edp-field-wrapper.region-wrapper
                  .post-code-wrapper
                    TextInput(name="postCode" :renderedTextLabel="$t('profile.editProfile.editProfilePostLabel')"
                      :key="updateKey10" :defaultValue="defaultPostCode"
                      :floatingLabel="true" vid="postCode" :disabledProps="true"
                      @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                      :skipIfEmpty="true" :clearFields="clearFields")

            .right-group
              .contacts-container-left
                .edp-field-wrapper.phone-block
                  .phone-wrapper
                    TextInput(name="epPhone" :renderedTextLabel="$t('profile.editProfile.editProfilePhoneLabel')"
                      :key="updateKey7" :defaultValue="defaultPhone"
                      :floatingLabel="true" vid="epPhone" :disabledProps="true"
                      @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                      :skipIfEmpty="true" :isError="isErrorPhone" :clearFields="clearFields")

                  button.phone-ver(v-if="!isPhoneVerified" type="button" @click="verifyPhone") {{ $t('profile.editProfile.editProfileVerifyButton') }}
                  span(v-else).phone-ver.ok {{ $t('profile.editProfile.editProfileVerifiedLabel') }}

                .edp-field-wrapper.email-block
                  .email-wrapper
                    TextInput(name="epEmail" :renderedTextLabel="$t('profile.editProfile.editProfileEmailLabel')"
                      :key="updateKey8" :defaultValue="defaultEmail"
                      :floatingLabel="true" vid="email" :disabledProps="true"
                      @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                      :skipIfEmpty="true" :isError="isErrorEmail" :clearFields="clearFields")

                  button.email-ver(v-if="!isEmailVerified" type="button" @click="verifyEmail") {{ $t('profile.editProfile.editProfileVerifyButton') }}
                  span(v-else).email-ver.ok {{ $t('profile.editProfile.editProfileVerifiedLabel') }}

              .contacts-container-right
                .social-wrapper
                  span.sw-title {{ $t('profile.editProfile.socialProfileLabel') }}

                  .inp-container
                    .fbk-wrapper(v-if="isFBExpanded")
                      TextInputValidationOff(name="fb" :defaultValue="defaultSocFB" @textInputEmit="listenTextInput")

                    .twr-wrapper(v-if="isTWRExpanded")
                      TextInputValidationOff(name="tw" :defaultValue="defaultSocTWR" @textInputEmit="listenTextInput")

                    .ytb-wrapper(v-if="isYTExpanded")
                      TextInputValidationOff(name="ytb" :defaultValue="defaultSocYT" @textInputEmit="listenTextInput")

                  .buttons-wrapper
                    .fbbutton(@click="handleSocial('fb')" :class="{ 'selected': isFBKSelected }")
                      .img-wrapper
                        img(:src="require('@/assets/images/personal/edit_profile/soc_buttons/fb_soc.svg')" alt="fbk")

                      .gplus-wrapper
                        img.vline(:src="require('@/assets/images/personal/edit_profile/v-line.svg')" alt="gvline")
                        img.gline(:src="require('@/assets/images/personal/edit_profile/g-line.svg')" alt="ggline")

                    .twbutton(@click="handleSocial('tw')" :class="{ 'selected': isTWRSelected }")
                      .img-wrapper
                        img(:src="require('@/assets/images/personal/edit_profile/soc_buttons/twr_soc.svg')" alt="twr")

                      .gplus-wrapper
                        img.vline(:src="require('@/assets/images/personal/edit_profile/v-line.svg')" alt="vkvline")
                        img.gline(:src="require('@/assets/images/personal/edit_profile/g-line.svg')" alt="vkgline")

                    .ytbbutton(@click="handleSocial('ytb')" :class="{ 'selected': isYTSelected }")
                      .img-wrapper
                        img(:src="require('@/assets/images/personal/edit_profile/soc_buttons/ytb_soc.svg')" alt="ytb")

                      .gplus-wrapper
                        img.vline(:src="require('@/assets/images/personal/edit_profile/v-line.svg')" alt="vkvline")
                        img.gline(:src="require('@/assets/images/personal/edit_profile/g-line.svg')" alt="vkgline")

          .edp-btn-wrapper
            button.edp-btn(:class="{ 'disabled': false, 'clicked': isButtonClicked }"
                          type="submit") {{ $t('profile.editProfile.editProfileButton') }}
</template>

<script>

import {isValidPhoneNumber} from 'libphonenumber-js';
import {extend} from 'vee-validate';
const TextInput = () => import('@/components/serviceComponents/textInput/TextInputSW');
const TextInputValidationOff = () => import('@/components/serviceComponents/textInput/TextInputValidationOffSW');
const Dropdown = () => import('@/components/serviceComponents/dropdown/DropdownSW');
const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');

import EditProfile from '../EditProfile.vue';
const GAME_PLACE_LANG = 'game_place_lang';

/**
 * проверка телефона пользователя,
 * isValidPhoneNumber метод из бибилиотеки libphonenumber-js
 */
function checkUserPhone(phone) {
  let lang = 'EN';

  if (localStorage.getItem(GAME_PLACE_LANG) != null) {
    lang = JSON.stringify(localStorage.getItem(GAME_PLACE_LANG)).split('-')[1];
  }

  if (phone && !/'+'/.test(phone)) {
    phone = '+' + phone;
  }

  if (isValidPhoneNumber(phone, lang)) {
    return true;
  } else {
    return false;
  }
}

extend('mobilePhone', value => {
  return checkUserPhone(value);
});

extend('minThree', value => {
  return value.length >= 3;
});

extend('minFour', value => {
  return value.length >= 4;
});

extend('minSix', value => {
  return value.length >= 6;
});

export default {
  extends: EditProfile,
  components: {
    CrossShadowOff,
    Dropdown,
    TextInput,
    TextInputValidationOff,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/personal/edit-profile_sw.scss";
</style>
