<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'EditProfile',
  data: () => ({
    isButtonClicked: false,
    clearFields: false,
    updateKey1: 0,
    updateKey2: 1,
    updateKey3: 2,
    updateKey4: 3,
    updateKey5: 4,
    updateKey6: 5,
    updateKey7: 6,
    updateKey8: 8,
    updateKey9: 9,
    updateKey10: 10,
    userData: {
      login: null,
      name: null,
      surname: null,
      day: 1,
      birthday: null,
      month: null,
      year: null,
      phone: null,
      email: null,
      sex: null,
      address: {
        country: null,
        city: null,
        address: null,
      },
      socials: null,
    },
    dateArray: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16',
      '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    yearArray: [],
    selectedDayValue: null,
    selectedMonthValue: null,
    selectedYearValue: null,
    selectedCountryValue: null,
    expandDaysDD: false,
    expandMonthDD: false,
    expandYearDD: false,
    expandCountryDD: false,
    isFBExpanded: false,
    isTWRExpanded: false,
    isYTExpanded: false,
    isFBKSelected: false,
    isTWRSelected: false,
    isYTSelected: false,
    isErrorEmail: false,
    initEmail: '',
    isErrorPhone: false,
    initPhone: '',
  }),
  computed: {
    ...mapGetters({
      currentLanguage: 'user/getLanguage',
      openSection: 'personal/getOpenSection',
      editProfile: 'personal/getEditProfile',
    }),
    monthArray() {
      return [
        {title: this.$t('profile.editProfile.firstMonthSelectLabel'), month: '01'},
        {title: this.$t('profile.editProfile.secondMonthSelectLabel'), month: '02'},
        {title: this.$t('profile.editProfile.thirdMonthSelectLabel'), month: '03'},
        {title: this.$t('profile.editProfile.fourthMonthSelectLabel'), month: '04'},
        {title: this.$t('profile.editProfile.fifthMonthSelectLabel'), month: '05'},
        {title: this.$t('profile.editProfile.sixthMonthSelectLabel'), month: '06'},
        {title: this.$t('profile.editProfile.seventhMonthSelectLabel'), month: '07'},
        {title: this.$t('profile.editProfile.eightMonthSelectLabel'), month: '08'},
        {title: this.$t('profile.editProfile.ninthMonthSelectLabel'), month: '09'},
        {title: this.$t('profile.editProfile.tenthMonthSelectLabel'), month: '10'},
        {title: this.$t('profile.editProfile.eleventhMonthSelectLabel'), month: '11'},
        {title: this.$t('profile.editProfile.twelfthMonthSelectLabel'), month: '12'},
      ];
    },
    defaultUsername() {
      return this.editProfile != null && this.editProfile.hasOwnProperty('login') ? this.editProfile.login : null;
    },
    defaultFirstname() {
      return this.editProfile != null && this.editProfile.hasOwnProperty('name') ? this.editProfile.name : null;
    },
    defaultLastname() {
      return this.editProfile != null && this.editProfile.hasOwnProperty('surname') ? this.editProfile.surname : null;
    },
    isPhoneVerified() {
      return this.editProfile != null && this.editProfile.hasOwnProperty('phone_status') ?
          (this.editProfile.phone_status === 'accept' && this.userData.phone === this.initPhone ) : false;
    },
    isEmailVerified() {
      return !this.isChangeEmail && (this.editProfile != null && this.editProfile.hasOwnProperty('email_status') ?
          (this.editProfile.email_status === 'accept' && this.userData.email === this.initEmail): false);
    },
    defaultCountry() {
      if (this.editProfile != null) {
        if (this.editProfile.hasOwnProperty('address')) {
          if (this.editProfile.address != null && this.editProfile.address.hasOwnProperty('country')) {
            return this.editProfile.address.country;
          }

          return null;
        }

        return null;
      }

      return null;
    },
    defaultCity() {
      if (this.editProfile != null) {
        if (this.editProfile.hasOwnProperty('address')) {
          if (this.editProfile.address != null && this.editProfile.address.hasOwnProperty('city')) {
            return this.editProfile.address.city;
          }

          return null;
        }

        return null;
      }

      return null;
    },
    defaultState() {
      if (this.editProfile != null) {
        if (this.editProfile.hasOwnProperty('address')) {
          if (this.editProfile.address != null && this.editProfile.address.hasOwnProperty('state')) {
            return this.editProfile.address.state;
          }

          return null;
        }

        return null;
      }

      return null;
    },
    defaultPostCode() {
      if (this.editProfile != null) {
        if (this.editProfile.hasOwnProperty('address')) {
          if (this.editProfile.address != null && this.editProfile.address.hasOwnProperty('post_code')) {
            return this.editProfile.address['post_code'];
          }

          return null;
        }

        return null;
      }

      return null;
    },
    defaultAddress() {
      if (this.editProfile != null) {
        if (this.editProfile.hasOwnProperty('address')) {
          if (this.editProfile.address != null && this.editProfile.address.hasOwnProperty('address')) {
            return this.editProfile.address.address;
          }

          return null;
        }

        return null;
      }

      return null;
    },
    defaultPhone() {
      return this.editProfile != null && this.editProfile.hasOwnProperty('phone') ? this.editProfile.phone : null;
    },
    defaultEmail() {
      return this.editProfile != null && this.editProfile.hasOwnProperty('email') ? this.editProfile.email : null;
    },
    defaultSocFB() {
      return this.userData.socials != null && this.userData.socials.hasOwnProperty('fb') ?
          this.userData.socials.fb: null;
    },
    defaultSocTWR() {
      return this.userData.socials != null && this.userData.socials.hasOwnProperty('tw') ?
          this.userData.socials.tw: null;
    },
    defaultSocYT() {
      return this.userData.socials != null && this.userData.socials.hasOwnProperty('ytb') ?
          this.userData.socials.ytb: null;
    },
    getCountries() {
      return this.editProfile != null && this.editProfile.hasOwnProperty('countries') ? this.editProfile.countries : [];
    },
  },
  methods: {
    ...mapActions({
      saveEditProfile: 'personal/doSaveEditProfile',
      fetchEditProfile: 'personal/fetchEditProfile',
      fetchUserProfile: 'user/fetchProfile',
      phoneActivateRequest: 'personal/doPhoneActivateRequest',
      emailActivateRequest: 'personal/doEmailActivateRequest',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    /**
     * метод-слушатель ввода данных из компонентов ввода
     */
    listenTextInput(data) {
      if (data) {
        switch (data.name) {
          case 'username': {
            this.userData.login = data.value;
            return;
          }
          case 'fname': {
            this.userData.name = data.value;
            return;
          }
          case 'lname': {
            this.userData.surname = data.value;
            return;
          }
          case 'city': {
            if (this.userData.address != null) {
              this.userData.address.city = data.value;
            }
            else {
              this.userData.address = {};
              this.userData.address.city = data.value;
            }

            return;
          }
          case 'address': {
            if (this.userData.address != null) {
              this.userData.address.address = data.value;
            }
            else {
              this.userData.address = {};
              this.userData.address.address = data.value;
            }

            return;
          }
          case 'state': {
            if (this.userData.address != null) {
              this.userData.address.state = data.value;
            }
            else {
              this.userData.address = {};
              this.userData.address.state = data.value;
            }

            return;
          }
          case 'postCode': {
            if (this.userData.address != null) {
              this.userData.address['post_code'] = data.value;
            }
            else {
              this.userData.address = {};
              this.userData.address['post_code'] = data.value;
            }

            return;
          }
          case 'epPhone': {
            this.userData.phone = data.value;

            if (this.initPhone === '' || this.initPhone === '+') {
              this.initPhone = data.value;
            }

            return;
          }
          case 'epEmail': {
            this.userData.email = data.value;

            if (this.initEmail === '') {
              this.initEmail = data.value;
            }
            
            return;
          }
          case 'fb': {
            if (this.userData.socials != null) {
              this.userData.socials.fb = data.value;
            }
            else {
              this.userData.socials = {};
              this.userData.socials.fb = data.value;
            }

            return;
          }
          case 'tw': {
            if (this.userData.socials != null) {
              this.userData.socials.tw = data.value;
            }
            else {
              this.userData.socials = {};
              this.userData.socials.tw = data.value;
            }

            return;
          }
          case 'ytb': {
            if (this.userData.socials != null) {
              this.userData.socials.ytb = data.value;
            }
            else {
              this.userData.socials = {};
              this.userData.socials.ytb = data.value;
            }

            return;
          }
        }
      }
    },
    /**
     * Активирование телефона по кнопке Verify.
     */
    async verifyPhone() {
      if (!this.userData.phone) {
        this.isErrorPhone = true;
      } else {
        this.isErrorPhone = false;
        await this.phoneActivateRequest(this.userData.phone);
        this.updateUserData();
      }
    },
    /**
     * Активирование по кнопке Verify будет отдельной задачей.
     */
    async verifyEmail() {
      if (!this.userData.email) {
        this.isErrorEmail = true;
      } else {
        this.isErrorEmail = false;
        await this.emailActivateRequest(this.userData.email);
        this.updateUserData();
      }
    },
    /**
     * Метод-обработчик для хранения изменений формы.
     */
    async handleEditProfile() {
      let handlerFlag = false;

      for (const key in this.userData) {
        if (this.userData[key] != null && key != 'day' && key != 'address') {
          handlerFlag = true;
        }
      }

      if (this.userData.address != null) {
        const addressValus = Object.values(this.userData.address);
        const elem = el => Boolean(el) === true;

        if (addressValus.some(elem)) {
          handlerFlag = true;
        }
      }

      if (handlerFlag) {
        const birthDayData = this.userData.year == null || this.userData.month == null ?
            null : `${this.userData.year}-${this.userData.month}-${this.userData.day}`;
        const address = this.userData.address != null ?
            {country: this.userData.address.country, city: this.userData.address.city,
              address: this.userData.address.address, state: this.userData.address.state,
            'post_code': this.userData.address['post_code']} : null;
        const dataForReq = {
          data: {
            phone: this.userData.phone,
            email: this.userData.email,
            login: this.userData.login,
            name: this.userData.name,
            surname: this.userData.surname,
            birthday: birthDayData,
            sex: this.userData.sex,
            socials: this.userData.socials,
            address: address,
          }
        };

        await this.saveEditProfile(dataForReq);
        // await this.updateUserData();
      }

      // for (let udField in this.userData) {
      //   if (udField === 'day') {
      //     this.userData['day'] = '01';
      //   } else {
      //     this.userData[udField] = null;
      //   }
      // }
    },
    /**
     * Метод-обработчик для выбора радиокнопки.
     */
    checkSex(arg) {
      if (arg === 'male') {
        this.userData.sex = 'male';

        if (document.querySelector('#male')) {
          document.querySelector('#male').checked = true;
        }
        if (document.querySelector('#female')) {
          document.querySelector('#female').checked = false;
        }
      } else {
        this.userData.sex = 'female';

        if (document.querySelector('#male')) {
          document.querySelector('#male').checked = false;
        }
        if (document.querySelector('#female')) {
          document.querySelector('#female').checked = true;
        }
      }
    },
    /**
     * Метод-обработчик для выбора даты.
     */
    listenToggle(data, arg) {
      if (data) {
        switch (arg) {
          case 'day': {
            this.userData.day = data;
            this.selectedDayValue = data;
            return;
          }
          case 'month': {
            this.userData.month = data.month;
            this.selectedMonthValue = this.$t(data.title);
            return;
          }
          case 'year': {
            this.userData.year = data;
            this.selectedYearValue = data;
            return;
          }
          case 'country': {
              if (this.userData.address != null) {
                this.userData.address.country = data;
              }
              else {
                this.userData.address = {};
                this.userData.address.country = data;
              }

            this.selectedCountryValue = data;
            return;
          }
        }
      }
    },
    /**
     *
     * @param ddArg string
     * преключатель активного дропдауна
     */
    switchDropdown(ddArg) {
      if (ddArg === 'days') {
        setTimeout(() => {
          this.expandDaysDD = !this.expandDaysDD;
        }, 300);
      } else if (ddArg === 'month') {
        setTimeout(() => {
          this.expandMonthDD = !this.expandMonthDD;
        }, 300);
      } else if (ddArg === 'year') {
        setTimeout(() => {
          this.expandYearDD = !this.expandYearDD;
        }, 300);
      } else if (ddArg === 'country') {
        setTimeout(() => {
          this.expandCountryDD = !this.expandCountryDD;
        }, 300);
      }
    },
    /**
     * генерируем последовательность годов для дропдауна
     * стартуем от текущего минус 18 лет, заканчиваем -
     * полученное значение минус 72 года.
     */
    generateYearsSeq() {
      let arr = [];
      const currentYear = new Date().getFullYear() - 18;
      for (let i = currentYear; i >= (currentYear - 75); i--) {
        arr.push(i);
      }
      this.yearArray = arr;
    },
    /**
     * обработчик для задания социальных сетей
     * @param socialArg
     */
    handleSocial(socialArg) {
      switch (socialArg) {
        case 'fb': {
          this.isFBExpanded = true;
          this.isFBKSelected = true;
          setTimeout(() => {
            this.isFBKSelected = false;
          }, 750);
          return;
        }
        case 'tw': {
          this.isTWRExpanded = true;
          this.isTWRSelected = true;
          setTimeout(() => {
            this.isTWRSelected = false;
          }, 750);
          return;
        }
        case 'ytb': {
          this.isYTExpanded = true;
          this.isYTSelected = true;
          setTimeout(() => {
            this.isYTSelected = false;
          }, 750);
          return;
        }
      }
    },
    /**
     * метод-обработчик на кресте закрытия формы для @1024
     */
    edCrossHandler() {
      this.$router.push('/');
      window.scrollTo(0, 0);
      this.SET_OPEN_PAGE('root');
    },
    /**
     * метод для заполнения исходных данных формы при открытии страницы
     */
    async updateUserData() {
      this.isErrorEmail = false;
      this.isErrorPhone = false;

      await this.fetchEditProfile().catch(e => console.error(e));
      await this.fetchUserProfile();

      this.$nextTick(() => {
        this.generateYearsSeq();

        if (this.editProfile != null) {
          for (const key in this.editProfile) {
            if (this.userData.hasOwnProperty(key)) {
              if (key === 'phone') {
                this.initPhone = this.editProfile[key];
              }
              if (key === 'email') {
                this.initEmail = this.editProfile[key];
              }
              if (key === 'address') {
                this.selectedCountryValue = this.editProfile[key] != null &&
                                            this.editProfile[key].hasOwnProperty('country') ?
                              this.editProfile[key]['country'] : this.$t('profile.editProfile.editProfileCountryLabel');
                this.userData.address.state =  this.editProfile[key] != null &&
                                               this.editProfile[key].hasOwnProperty('state') ?
                    this.editProfile[key]['state'] : this.$t('profile.editProfile.editProfileStateLabel');
                this.userData.address['post_code'] =  this.editProfile[key] != null &&
                                               this.editProfile[key].hasOwnProperty('post_code') ?
                    this.editProfile[key]['post_code'] : this.$t('profile.editProfile.editProfilePostLabel');
              }
              if (key !== 'birthday' && key !== 'sex') {
                this.userData[key] = this.editProfile[key];
              }
              else if (key === 'sex') {
                this.checkSex(this.editProfile[key]);
              }
              else if (key === 'birthday') {
                if (this.editProfile[key] != null && this.editProfile[key] !== '') {
                  const [year, month, day] = this.editProfile[key].split('-');
                  this.userData.day = day;
                  this.selectedDayValue = day;
                  this.userData.month = month;
                  this.selectedMonthValue = this.monthArray.find(data => data.month == month).title;
                  this.userData.year = year;
                  this.selectedYearValue = year;
                  this.userData[key] = this.editProfile[key];
                }
                else {
                  this.selectedDayValue = this.$t('profile.editProfile.selectDayLabel');
                  this.selectedMonthValue = this.$t('profile.editProfile.selectMonthLabel');
                  this.selectedYearValue = this.$t('profile.editProfile.selectYearLabel');
                }
              }
            }
            else {
              this.userData[key] = this.editProfile[key];
            }
          }
        }
        else {
          this.selectedDayValue = this.$t('profile.editProfile.selectDayLabel');
          this.selectedMonthValue = this.$t('profile.editProfile.selectMonthLabel');
          this.selectedYearValue = this.$t('profile.editProfile.selectYearLabel');
          this.selectedCountryValue = this.$t('profile.editProfile.editProfileCountryLabel');
        }
      });
    },
  },
  watch: {
    isButtonClicked(newClcikedVal) {
      if (newClcikedVal) {
        setTimeout(() => {
          this.isButtonClicked = false;
        }, 500);
      }
    },
    openSection(newSection) {
      if (newSection !== 'editProfile') {
        this.clearFields = true;
      }

      if (newSection === 'editProfile') {
        this.updateUserData();
        this.clearFields = false;
        this.updateKey1++;
        this.updateKey2++;
        this.updateKey3++;
        this.updateKey4++;
        this.updateKey5++;
        this.updateKey6++;
        this.updateKey7++;
        this.updateKey8++;
        this.updateKey9++;
        this.updateKey10++;
      }
    },
    currentLanguage(newLang, oldLang) {
      if (newLang !== oldLang) {
        if (isNaN(parseInt(this.selectedDayValue))) {
          this.selectedDayValue = this.$t('profile.editProfile.selectDayLabel');
        }

        if (isNaN(parseInt(this.selectedMonthValue))) {
          if (this.selectedMonthValue == null) {
            this.selectedMonthValue = this.$t('profile.editProfile.selectMonthLabel');
          }
          else {
              const month = this.monthArray.find(month => month.month == this.userData.month);

              if (month) {
                this.selectedMonthValue = month.title;
              }
          }
        }

        if (isNaN(parseInt(this.selectedYearValue))) {
          this.selectedYearValue = this.$t('profile.editProfile.selectYearLabel');
        }
      }
    },
    editProfile: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != null && oldVal == null && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.updateUserData();
        }

        // обновляем ключи для еомпонентов полей ввода
        if (newVal != null && oldVal != null && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.updateKey1++;
          this.updateKey2++;
          this.updateKey3++;
          this.updateKey4++;
          this.updateKey5++;
          this.updateKey6++;
          this.updateKey7++;
          this.updateKey8++;
          this.updateKey9++;
          this.updateKey10++;
        }
      }
    },
    defaultSocFB(newFB) {
      if (newFB != null) {
        this.isFBExpanded = true;
      }
    },
    defaultSocTWR(newFB) {
      if (newFB != null) {
        this.isTWRExpanded = true;
      }
    },
    defaultSocYT(newFB) {
      if (newFB != null) {
        this.isYTExpanded = true;
      }
    }
  },
  async mounted() {
    await this.updateUserData();
  },
  beforeDestroy() {
    this.isButtonClicked = false;
    this.clearFields = true;
  },
};
</script>

<style lang="scss" scoped>
</style>
